<template>
    <footer>
        <button class="format-footer cancel" @click="$emit('editaInclusao')">
            <h3>Cancelar</h3>
        </button>
        <button :disabled="isSending" class="format-footer save" type="submit" @click="enviaForm">
            <h3>Salvar</h3>
        </button>
    </footer>
</template>

<script>
export default {
    methods: {
        enviaForm() {
            this.$emit('envia-form');
        },
    },

    props: ['isSending'],
};
</script>

<style scoped>
footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: grid;
    z-index: 2;
    grid-template-columns: 1fr 1fr;
    background-color: var(--azul-escuro);
    box-shadow: 0 0 10px #cdcdcd;
}

.format-footer {
    place-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 19px;
    border: none;
}

.format-footer:first-child {
    background-color: #fff;
}

.format-footer h3 {
    font-size: 18px;
    margin-top: 5px;
    color: var(--branco);
    font-weight: 500;
}

.cancel h3 {
    color: #000;
}

.save {
    background-color: var(--azul-escuro);
    border: none;
}

.save h3 {
    font-weight: 600;
}
</style>
